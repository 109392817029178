import React, {useState} from 'react';
import {useHistory, useParams} from "react-router-dom";
import OnboardLayout from "./OnboardLayout";
import "react-datepicker/dist/react-datepicker.css";
import logoNissanNow from "../../assets/images/logo@2x.png";
import {useTranslation} from 'react-i18next';
import UserService from "../../services/UserService";
import AddCustomerHeaderButtons from "./AddCustomerHeaderButtons";
import ReactHtmlParser from 'react-html-parser';

const UserInformation = (props) => {
    let params = useParams();
    const { i18n } = useTranslation();
    const history = useHistory();
    const [vin, setVin] = useState(params.id ?? (localStorage.getItem('vin') ? localStorage.getItem('vin') : ''))
    const [customer, setCustomer] = useState((localStorage.getItem('customer') && localStorage.getItem('customer') !== 'undefined') ? JSON.parse(localStorage.getItem('customer')) : null);
    const [car, setCar] = useState((localStorage.getItem('car') && localStorage.getItem('car') !== 'undefined') ? JSON.parse(localStorage.getItem('car')) : null);
    const [address, setAddress] = useState((localStorage.getItem('address') && localStorage.getItem('address') !== 'undefined') ? JSON.parse(localStorage.getItem('address')) : null);

    const [errorEmailConfirm, setErrorEmailConfirm] = useState(false);

    const handleSubmit = (event) => {
        event.preventDefault();

        UserService.sendCustomerConnectEmail({
            "email":  customer.email,
        }).then((res) => {
            history.push({
                    pathname:'/new-link-sent',
                    state: {
                        email: customer.email
                    }
                }
            )
        }).catch((error) => {
            console.log('error', error);
            setErrorEmailConfirm(error);
        });
    }

    const customerNewInfos = (event) => {

        UserService.getCar({
            "vin": vin,
            "token": localStorage.getItem('token')
        })
            .then((res) => {
                localStorage.setItem('vin', vin);
                localStorage.setItem('car', JSON.stringify(res.data.car));
                localStorage.setItem('customer', null);
                localStorage.setItem('address', null);
                localStorage.setItem('image', res.data.car.modelImage);
                localStorage.setItem('model', res.data.car.modelName);
                history.push({
                    pathname: '/user-car-information',
                })
            })
            .catch((error) => {
                console.log(error)
                history.push({
                    pathname: '/user-car-associated',
                    state: {
                      errorMessage:  error.toString()
                    }
                })
            })
    }

    const {t} = useTranslation();

    return (

        <div className="wrapper wrapper-with-nav pt-5">
            <AddCustomerHeaderButtons />
            <div className="container">

                <main>
                    <OnboardLayout>

                        <div className="text-center board-info-title mb-4">
                            <h2>{t('WE HAVE FOUND A CLIENT ASSOCIATED')} <br/> {t('WITH THIS VIN')}</h2>
                            <span className="grey-title">{t('Please check the client ID before proceeding')}</span>
                        </div>

                        <div className="row justify-content-center">
                            <div className="col-lg-12 col-xl-10">
                                <div className="white-container">
                                <h2 className="with-border"> {t('We have found a client associated with this vin')} </h2>
                                <form onSubmit={handleSubmit}>
                                    <div className="row mt-4">
                                        <div className="col-md-6" >
                                            {customer &&
                                                <div className="input-units">
                                                    {vin && <h2>{vin}</h2>}
                                                    <h2>{customer.firstName} {customer.lastName}</h2>
                                                    <p className={"font-weight-bold"}>{customer.email}</p>
                                                    {address && <p>{address.streetNumber} {address.streetName}<br />{address.postalCode} {address.city}</p>}
                                                </div>
                                            }
                                            <div className="input-units">
                                                <p>{t('Please check the infos before proceeding')}</p>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                          <button type="button" className="button red mx-auto mr-md-0 mb-4" onClick={()=> customerNewInfos() }><span>{ t('Not owner') }</span></button>
                                          <button type="submit" className="button white mx-auto mr-md-0"><span>{ReactHtmlParser(t('User ok send link'))}</span></button>
                                          {errorEmailConfirm && <p className="font-weight-bold my-4"><span className="error color-error">Il y a eu une erreur pour associer le compte</span></p>}
                                        </div>
                                    </div>
                                </form>
                                </div>
                            </div>
                        </div>
                    </OnboardLayout>

                </main>

            </div>

        </div>

    );
}

export default UserInformation
