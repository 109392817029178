import React from 'react';
import BasicPage from "../components/BasicPage";

import { useTranslation } from 'react-i18next';

const PasswordChoiceConfirmation = (props) => {
  const { t } = useTranslation();
  let redirect = process.env.REACT_APP_CUSTOMER_FRONT_URL;
  const pageTitle = t('Password set');
  const pageContent = t('Your password has been successfully set and linked to your account.');
  const buttonText = t('Go to Nissan Le Club');

  return (
      <BasicPage
          external={true}
          icon="icon-check"
          title={pageTitle}
          content={pageContent}
          buttonText={buttonText}
          buttonLink={redirect}
      />
  );
}

export default PasswordChoiceConfirmation
