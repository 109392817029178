import React, { useState, useEffect } from 'react';
import { useHistory, Link } from "react-router-dom";

import BasicLayout from "../../components/BasicLayout";

import UserService from "../../services/UserService";

import { useTranslation } from 'react-i18next';

const SignIn = (props) => {
  const history = useHistory();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [checkDomaine, setCheckDomain] = useState(false);
  const { t, i18n } = useTranslation();

  const redirectDomain = (url) => {
    //find & remove protocol (http, ftp, etc.) and get hostname
    let hostname;

    if (url.indexOf("//") > -1) {
      hostname = url.split('/')[2];
    }
    else {
      hostname = url.split('/')[0];
    }

    //find & remove port number
    hostname = hostname.split(':')[0];
    //find & remove "?"
    hostname = hostname.split('?')[0];

    if ((process && hostname === process.env.REACT_APP_BE_DEALER_YOUPLUS_URL) ||  window.location.href.includes("-BE")) {
      i18n.changeLanguage('fr');
    }
    else if ((process && hostname === process.env.REACT_APP_NL_DEALER_YOUPLUS_URL) || window.location.href.includes("-NL")) {
      i18n.changeLanguage('nl');
    }

    return hostname;
  }

  useEffect(() => {
    if(localStorage.getItem('token') !== 'null' && localStorage.getItem('role')) {
      //history.push('/dealerboard');

      let role = localStorage.getItem('role');
      if (role && role.includes('ROLE_ADMIN')){
        history.push('/dealerboard');
      } else if(role && role.includes('ROLE_DEALER_DIRECTOR')) {
        history.push('/dealerboard');
      } else {
        history.push('/customerboard');
      }
    }
    if (!checkDomaine) {
      redirectDomain(window.location.href);
      setCheckDomain(true);
    }
  }, [])

  const handleSubmit = (event) => {
    event.preventDefault();
    UserService.login({
      "username": email,
      "password": password,
    }).then((response) => {
      if(response.status === 200 && response.data.token !== null) {
        if (typeof window !== 'undefined') {
          let token = response.data.token
          localStorage.setItem('token', 'Bearer ' + token);

          UserService.getMeInfo({
            "email": email,
            "token": token,
          }).then((res) => {
            localStorage.setItem('role', res.data.roles);
            localStorage.setItem('dealer', res.data.dealer);
            localStorage.setItem('dealerName', res.data.dealerName);
            localStorage.setItem('dealerGroup', res.data.dealerGroup);
            localStorage.setItem('email', email);
            i18n.changeLanguage(res.data.locale);

          }).then((res) => {
              let roles = localStorage.getItem('role');
              if(roles.includes('ROLE_ADMIN')){
                history.push('/dealerboard');
              } else if(roles.includes('ROLE_DEALER_DIRECTOR'))
              {
                history.push('/dealerboard');
              }
              else if(roles.includes('ROLE_DEALER'))
              {
                history.push('/customerboard');
              }
          })
        }
      }
    })
    .catch((error) => {
      setPasswordError(true);
      if (error.toString().includes("404")) {
        console.log('Email not found')
      }
      else if (error.toString().includes("401")) {
        console.log('Wrong password');
        //TODO add error message
      }
    })
  }

  const handleChangeEmail = (event) => {
    setEmail(event.target.value);
  }

  const handleChangePassword = (event) => {
    setPassword(event.target.value);
    setPasswordError(!validatePassword(event.target.value));
  }

  const validatePassword = (string) =>{
    var reg = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/g;
    return reg.test(string);
  }

  return (
    <BasicLayout>

      <h2 className="with-border">{t('Sign In')}</h2>
      <p>{t('Please fill your email and password')}.</p>

      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-md-6 mb-4">
            <label className="required form-label">Email</label>
            <div className="form-group">
              <input type="text" name="name" value={email} onChange={handleChangeEmail} className={`form-control ${passwordError ? "error" : ""}`} />
            </div>
          </div>
          <div className="col-md-6 mb-4">
            <div className="form-group">
              <label className="required form-label">{t('Password')}</label>
              <div className="password-wrapper">
                <input type={isPasswordVisible ? "text" : "password"} name="password" value={password} onChange={handleChangePassword} className={`form-control ${passwordError ? "error" : ""}`} />
                <span className={"icon-eye toggle-pwd"} onClick={()=> setIsPasswordVisible(!isPasswordVisible)}></span>
              </div>
              {passwordError && <div><span className="error color-error">{t('Wrong credentials')}</span></div>}
            </div>
            <div className="form-group">
              <div className="form-text">
                <Link to="/forgot-password">{t('Forgot Password')}</Link>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="required-field mb-4"><span>*</span>{t('mandatory fields')}</div>
          </div>
          <div className="col-md-6">
            <button type="submit" className="button red mx-auto mr-md-0">{t('Sign In')}</button>
          </div>
        </div>
      </form>
    </BasicLayout>
  );
}


export default SignIn
