import React from 'react';
import {useHistory} from 'react-router-dom';
import BasicPage from "../BasicPage";

import { useTranslation } from 'react-i18next';

const AccountUpdated = (props) => {
  const history = useHistory();

  const { t } = useTranslation();

  return (
    <>
      <BasicPage icon="icon-check" title={t('Account updated')} content={t('Account is updated')} buttonText={t('Back to list')} buttonLink="/dealerboard" />
    </>
  );
}

export default AccountUpdated
