import React, { useEffect, useState } from 'react';
import {useHistory} from "react-router-dom";
import OnboardLayout from "./OnboardLayout";
import UserService from "../../services/UserService";
import "react-datepicker/dist/react-datepicker.css";
import logoNissanNow from "../../assets/images/logo.png";
import {useTranslation} from 'react-i18next';
import Loader from "react-loader-spinner";
import AddCustomerHeaderButtons from "./AddCustomerHeaderButtons";


const UserAddCar = (props) => {
    localStorage.setItem('vin', '')
    //console.log('user add car');

    const history = useHistory();
    const [vin, setVin] = useState(localStorage.getItem('vin') ? localStorage.getItem('vin') : '')
    const [errorVIN, setErrorVIN] = useState(false);
    const [loader, setLoader] = useState(false);

    useEffect(() => {
      setErrorVIN(false)
    }, [vin]);

    const handleSubmit = (event) => {
        event.preventDefault();
        setLoader(true);

        var vinPattern = /^[A-HJ-NPR-Z0-9]{17}$/;

        if (vinPattern.test(vin)) {
          setErrorVIN(false)

          UserService.getCar({
            "vin": vin,
            "token": localStorage.getItem('token')
          })
            .then((res) => {
              localStorage.setItem('vin', vin);
              localStorage.setItem('car', JSON.stringify(res.data.car));
              localStorage.setItem('customer', JSON.stringify(res.data.customer));
              localStorage.setItem('address', JSON.stringify(res.data.address));
              localStorage.setItem('image', res.data.car.modelImage);
              localStorage.setItem('model', res.data.car.modelName);

              history.push({
                  pathname: '/user-found',
                  state: {
                    email: JSON.stringify(res.data.customer.email),
                  }
                }
              )
            })
            .catch((error) => {
              if(localStorage.getItem('token') === null){
                history.push({
                  pathname: '/login'
                })
              }
              if (error.toString().includes("400")
                    ||error.toString().includes("404")) {
                history.push({
                  pathname: '/user-car-associated',
                  state: {
                    errorMessage:  t('This VIN is not recognized')
                  }
                })
              } else if (JSON.parse(localStorage.getItem('customer')) === null) {
                history.push({
                  pathname: '/user-car-information',
                  state: {
                    errorMessage: t('The car you wish to add has no user')
                  }
                })
              } else {
                history.push({
                  pathname: '/user-car-associated',
                  state: {
                    errorMessage:  t('The car you wish to add is already associated with another user')
                  }
                })
              }


            });

        } else {
          setErrorVIN(true)
        }

    }

    const {t} = useTranslation();

    const showContent = (loader) => {
      if(loader == true)
      {
        return (
          <div style={{ textAlign: "center" }}>
            <Loader
              type="TailSpin"
              color="#C0092D"
              height={100}
              width={100}
            />
          </div>
        )
      } else {
        return (
          <form onSubmit={handleSubmit}>
            <label className="required form-label">{t('IDENTIFICATION NUMBER (VIN)')}</label>
            <div className="row">
              <div className="form-group col-md-8">
                <input type="text" name="vin" value={vin}
                       onChange={event => setVin(event.target.value)} className="form-control {errorVIN ? 'color-error' : ''}"
                       placeholder="Ex: SJNFAAJ1DKJFLSS" />

                {errorVIN && <div><span className="error color-error">Le VIN est au mauvais format</span></div>}
              </div>
              <div className="col-md-4">
                <button type="submit" className="button red justify-button">{t('ADD')}</button>
              </div>
            </div>
            <div className="row pt-2">
              <p>{t("The identification number (VIN) is located in the 'E' zone of your vehicle registration document. It usually has 17 characters, for recent vehicles")}</p>
              <a className="with-quote mb-2 mt-2" href="/example-identification">{t('View an example ?')}</a>
            </div>
            <div className="row">
              <p className="required-field mt-2 mb-4"><span>*</span>{t('mandatory fields')
              }</p>
            </div>
          </form>
        )
      }
    }

  return (

    <div className="wrapper wrapper-with-nav pt-5">
      <AddCustomerHeaderButtons />
      <div className="container">
        <main>
          <OnboardLayout>
            <div className="text-center board-info-title mb-4"></div>
            <div className="row justify-content-center">
              <div className="col-lg-12 col-xl-10">
                <div className="white-container">
                  <h2 className="with-border-top with-border-bottom">{t('ADD A CAR WITH')}</h2>
                  {
                    showContent(loader)
                  }
                </div>
              </div>
            </div>
          </OnboardLayout>
        </main>
      </div>
    </div>

  );
}

export default UserAddCar
