import React, {useState, useEffect} from 'react';
import {useHistory, useParams} from "react-router-dom";
import OnboardLayout from "./OnboardLayout";
import "react-datepicker/dist/react-datepicker.css";
import validator from 'validator'
import UserService from "../../services/UserService";
import logoNissanNow from "../../assets/images/logo.png";
import {useTranslation} from 'react-i18next';

const UserCongratulations = (props) => {
    const [user_id, setuser_id] = useState(JSON.parse(localStorage.getItem('user_id')));
    const [language, setLanguage] = useState('');
    const history = useHistory();

    useEffect(() => {
        UserService.getUserById({
            "id": user_id.id
        }).then((res) => {
            setLanguage(res.data.user.language);
        }).catch((error) => {
            console.log(error);
        })
      }, [])

      const clear = () => {
          localStorage.clear();
      }

      const linkReward = (language) => {
          if (language == 'fr-BE' || language == 'fr-LU')
          {
            return <a href={'https://test.nissan-youplus.mindoza.io/'+language+'/maintenance/timeline'} onClick={clear} className="button red mx-auto mr-md-0">{t('Get My rewards')}</a>
          } else {
            return <a href={'https://test.nissan-youplus.mindoza.io/'+language+'/rewards/reward-history'} onClick={clear} className="button red mx-auto mr-md-0">{t('Get My rewards')}</a>
          }
      }

    const {t} = useTranslation();

    return (
        <div className="container">
            <main>
                <OnboardLayout>
                    <div className="row justify-content-center">
                        <div className="col-lg-12 col-xl-10">
                            <div className="white-container">
                                <div className="row">
                                    <div className="col-md-12 text-center">
                                        <h1>{t('Congratulations')}</h1>
                                        <span className="icon-cup icon fs-50 bronze"></span>
                                        <h2 className="mt-3">{t('You obtained the BRONZE level')}</h2>
                                        <p>{t("You can now enjoy your time on NISSAN LE CLUB and get your exclusive rewards.")}</p>
                                    </div>
                                </div>
                                <div className="row mt-5">
                                    <div className="col-md-12">
                                        {linkReward(language)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </OnboardLayout>
            </main>
        </div>
    );
}

export default UserCongratulations
