import React from 'react'
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import TimelineService from "../../services/TimelineService";

export const ExportToExcel = ({ exportArray, fileName, title }) => {
  // const fileType =
  //   "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  // const fileExtension = ".xlsx";

  const exportToCSV = (exportArray, fileName) => {


    try {
      const link = document.createElement('a');

      console.log('Click ExportToExcel', exportArray);

      // Filtrer les objets avec une valeur pour la propriété "vin"
      const filteredExportArray = exportArray.filter(data => data.vin !== null && data.vin !== undefined);

      console.log('filteredExportArray', filteredExportArray);

      // Créer un tableau contenant uniquement les valeurs de la propriété "vin"
      const vinsWithValues = filteredExportArray.map(data => data.vin);

      console.log('vinsWithValues', vinsWithValues);

      TimelineService.getTimelinesCSVForVins({
        token: localStorage.getItem('token'),
        vinList: vinsWithValues
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        link.href = url;
        link.setAttribute('download', fileName+'.csv'); // Nom du fichier à télécharger
        document.body.appendChild(link);
        link.click();

        // Nettoyer et enlever le lien
        link.parentNode.removeChild(link);
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.log(error);
      });
    } catch (error) {
      console.error('Il y a eu un problème avec la demande de téléchargement: ', error);
    }

    // const ws = XLSX.utils.json_to_sheet(apiData);
    // const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    // const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    // const data = new Blob([excelBuffer], { type: fileType });
    // FileSaver.saveAs(data, fileName + fileExtension);


  };

  return (
   <a className="text-decoration-none" title={title} href="#" onClick={(e) => { exportToCSV(exportArray, fileName);e.preventDefault();}}> <span className="icon-download icon"></span></a>
    // <button className="button red" onClick={(e) => exportToCSV(apiData, fileName)}>Export Timeline</button>
  );
};