import React, { useState } from 'react';
import { useHistory, useParams } from "react-router-dom";
import BasicLayout from "../../components/BasicLayout";
import UserService from "../../services/UserService";

import { useTranslation } from 'react-i18next';

const ChoosePassword = (props) => {
  const history = useHistory();
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  let user_id = useParams();

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!passwordError && password !== ''){
      //Appel API
      UserService.updatePassword({
        "uuid": user_id,
        "password": password,
      })
      .then((res) => {
        history.push({
          pathname: '/password-choice-confirmation'
        })
      })
      .catch((error) => {
        console.log(error)
      })
    }
  }

  const handleChangePassword = (event) => {
    setPassword(event.target.value);
    setPasswordError(!validatePassword(event.target.value));
  }

  const validatePassword = (string) =>{
    var reg = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/g;
    return reg.test(string);
  }

  const { t } = useTranslation();

  return (
    <BasicLayout>

      <h2 className="with-border">{t('Choose your password')}</h2>
      <p>{t('Please choose a password to activate')}.</p>

      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-md-6 mb-4">
            <div className="form-group">
              <label className="required form-label">{t('CHOOSE A NEW PASSWORD')}</label>
              <div className="password-wrapper">
                <input type={isPasswordVisible ? "text" : "password"} name="password" value={password} onChange={(event) =>handleChangePassword(event)} className={`form-control ${passwordError ? "error" : ""}`} />
                <span className={"icon-eye toggle-pwd"} onClick={()=> setIsPasswordVisible(!isPasswordVisible)}></span>
              </div>
              {passwordError && <div><span className="error color-error">{t('Minimum of 8 characters, should at least include one digit, one capital letter')}</span></div>}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <button type="submit" className="button red mx-auto mr-md-0">{t('Submit this password')}</button>
          </div>
        </div>
      </form>
    </BasicLayout>
  );
}


export default ChoosePassword
