import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

import PrivateRoute from './components/PrivateRoute'

import SignIn from './pages/Session/SignIn';
import ForgotPassword from './pages/Session/ForgotPassword';
import ResetLinkSent from './pages/ResetLinkSent';
import ResetLinkSentCustomer from './pages/ResetLinkSentCustomer';
import NewLinkSent from './pages/NewLinkSent';
import PasswordResetConfirmation from './pages/PasswordResetConfirmation';
import ResetLinkExpired from './pages/ResetLinkExpired';
import AccountCreated from './components/Salesperson/AccountCreated';
import AccountUpdated from './components/Salesperson/AccountUpdated';
import AccountDeleted from './components/Salesperson/AccountDeleted';
import UserSetEmail from './components/Onboard/UserSetEmail';
import UserClientInfo from './components/Onboard/UserClientInfo';
import UserClientInfoNext from './components/Onboard/UserClientInfoNext';
import UserAddCar from './components/Onboard/UserAddCar';
import UserCarInformation from './components/Onboard/UserCarInformation';
import UserCarFound from './components/Onboard/UserCarFound';
import UserCarAssociated from './components/Onboard/UserCarAlreadyAssociated';
import UserLastMaintenance from './components/Onboard/UserLastMaintenance';
import UserAccountCreated from './components/Onboard/UserAccountCreated';
import ExampleIdentificationNumber from './components/Onboard/ExampleIdentificationNumber';
import UserCongratulations from './components/Onboard/UserCongratulations';
import ResetPassword from './pages/Session/ResetPassword';
import Deaderboard from './pages/Dealerboard';
import './assets/sass/global.sass';
import ChoosePassword from './pages/Session/ChoosePassword';
import CustomerChoosePassword from './pages/Session/CustomerChoosePassword';
import PasswordChoiceConfirmation from './pages/PasswordChoiceConfirmation';
import PasswordCustomerChoice from './pages/PasswordCustomerChoice';
import UserChoosePassword from './components/Onboard/UserChoosePassword';
import UserContactPreferences from './components/Onboard/UserContactPreferences';
import Customerboard from './pages/Customerboard';
// import DirectorBoard from './pages/DirectorBoard';
import EmailAlready from "./components/Onboard/EmailAlready";
import Timeline from "./components/Timeline/timeline";
import UserInformation from "./components/Onboard/UserInformation";
import AddCustomerHeaderButtons from "./components/Onboard/AddCustomerHeaderButtons";

export default function App() {

  return (
    <Router>
      <Switch>
        <Route path="/forgot-password">
          <ForgotPassword />
        </Route>

        <Route path="/reset-password/:id">
          <ResetPassword />
        </Route>

        <Route path="/reset-link-sent">
          <ResetLinkSent />
        </Route>

        <Route path="/reset-link-sent-customer">
          <ResetLinkSentCustomer />
        </Route>

        <Route path="/new-link-sent">
          <NewLinkSent />
        </Route>

        <Route path="/password-reset-confirmation">
          <PasswordResetConfirmation />
        </Route>

        <Route path="/reset-link-expired">
          <ResetLinkExpired />
        </Route>

        <Route path="/account-created">
          <AccountCreated />
        </Route>

        <Route path="/account-updated">
          <AccountUpdated />
        </Route>

        <Route path="/password-choice/:id">
          <ChoosePassword />
        </Route>

        <Route path="/password-customer-choice/:id">
          <CustomerChoosePassword />
        </Route>

        <Route path="/password-user-choice/:id">
          <UserChoosePassword />
        </Route>

        <Route path="/user-contact-preferences">
          <UserContactPreferences />
        </Route>

        <Route path="/user-timeline/:vin">
          <Timeline />
        </Route>

        <Route path="/password-choice-confirmation">
          <PasswordChoiceConfirmation />
        </Route>

        <Route path="/password-customer-choice-confirmation">
          <PasswordCustomerChoice />
        </Route>

        <Route path="/account-deleted">
          <AccountDeleted />
        </Route>

        <Route path="/user-set-email">
          <UserSetEmail />
        </Route>

        <Route path="/user-client-info">
          <UserClientInfo />
        </Route>

        <Route path="/user-client-info-next">
          <UserClientInfoNext />
        </Route>

        <Route path="/user-congratulations">
          <UserCongratulations />
        </Route>

        <Route path="/user-add-car">
          <UserAddCar />
        </Route>

        <Route path="/example-identification">
          <ExampleIdentificationNumber />
        </Route>

        <Route path="/user-car-information">
          <UserCarInformation />
        </Route>

        <Route path="/user-found">
          <UserInformation />
        </Route>

        <Route path="/user-car-found">
          <UserCarFound />
        </Route>

        <Route path="/user-car-associated">
          <UserCarAssociated />
        </Route>

        <Route path="/user-last-maintenance">
          <UserLastMaintenance />
        </Route>

        <Route path="/user-account-created">
          <UserAccountCreated />
        </Route>

        <Route path="/email-already-use">
          <EmailAlready />
        </Route>

        <Route path="/header-add-customer">
          <AddCustomerHeaderButtons />
        </Route>

        <PrivateRoute path="/dealerboard" component={Deaderboard} />

        <PrivateRoute path="/customerboard" component={Customerboard} />

        {/*<PrivateRoute path="/directorboard" component={DirectorBoard} />*/}

        <Route path="/">
          <SignIn />
        </Route>

      </Switch>

    </Router>
  );
}
