import React, {useState} from 'react';
import {useHistory, useParams} from "react-router-dom";
import OnboardLayout from "./OnboardLayout";
import "react-datepicker/dist/react-datepicker.css";
import logoNissanNow from "../../assets/images/logo.png";
import {useTranslation} from 'react-i18next';
import { useLocation } from 'react-router-dom'

const AddCustomerHeaderButtons = (props) => {
    const history = useHistory();
    let params = useParams();
    const logout = () => {
        localStorage.removeItem('token');
        localStorage.clear();
        history.push('/');
    };
    const {t} = useTranslation();
    function GetLocation() {
        const location = useLocation();
        return location.pathname
    }
    const currentLocation = GetLocation()
    const addCarPages = [
        '/user-add-car',
        '/user-car-information',
        '/user-found',
        '/user-car-found',
        '/user-last-maintenance',
        '/example-identification',
    ]
    const setEmailPages = [
        '/email-already-use',
        '/user-set-email',
    ]
    const clientInfosPages = [
        '/user-client-info-next',
        '/user-client-info',
    ]
    const userAccountCreatedPages = [
        '/user-account-created',
    ]
    function GetActiveTab(activePages) {
        let activeHeaderButton = ''
        if (activePages.indexOf(currentLocation) > -1) {
            activeHeaderButton = 'active'
        }
        return activeHeaderButton
    }

    return (

        <div>
            <header className="header py-2">
                <div className="container d-flex align-items-center">
                    <img src={logoNissanNow} alt="Nissan Le Club" width="160" className="mr-auto logo-width"/>

                    <span className="dark-red cursor-pointer" onClick={() => logout()}><span
                        className="icon-logout-2 icon align-middle"></span>{t('Logout')}</span>

                </div>
            </header>
            <div className="bar-step">

                <a href="/customerboard" className="button white back align-self-center">{t('Back to client list')}</a>

                <a className={"step1 " + GetActiveTab(addCarPages)}>
                    <span className="icon-car icon"></span>
                    <span className="d-none d-md-block text text-dealer-favorite">{t('YOUR CAR')}</span>
                </a>
                <a className={"step1 " + GetActiveTab(setEmailPages)}>
                    <span className="icon-inbox icon"></span>
                    <span className="d-none d-md-block text">{t('SET EMAIL')}</span>
                </a>
                <a className={"step1 "+ GetActiveTab(clientInfosPages) }>
                    <span className="icon-profile icon"></span>
                    <span className="d-none d-md-block text text-dealer-favorite">{t('CLIENT INFO')}</span>
                </a>
                <a to="#" className={"step3 " + GetActiveTab(userAccountCreatedPages)}>
                    <span className="icon-check icon"></span>
                    <span className="d-none d-md-block text">{t('CONFIRMATION')}</span>
                </a>
            </div>
        </div>

    );
}

export default AddCustomerHeaderButtons
