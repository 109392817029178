import axios from 'axios'

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
      Accept:         'application/ld+json',
      'Content-Type': 'application/json',
  }
});

export default {
  getSalesperson: async function(data) {
    try {
      const encodedGroupName = encodeURIComponent(data.groupName);
      const url = '/dealerplatform/staff'
      const urlWithParams = data.groupName ? `${url}/${encodedGroupName}` : url;

      const response = await instance.get(urlWithParams, {
        headers: {
          'Authorization': data.token
        }
      });
      return response;
    } catch (error){
      throw error;
    }
  },

  getDealerShipUsers: async function(data) {
    // TODO : ajouter le filtre dyanmique avec data.id et data.filtre et le cas NULL
    try {
      let filters = '';
      if(data.filter) {
        filters = '?filter=' + data.filter;
        filters += '&id=' + data.id;
      }
      const response = await instance.get("/dealerplatform/clients" + filters, {
        headers: {
          'Authorization': data.token
        }
      });
      return response;
    } catch (error){
      throw error;
    }
  },

  getDirectoDealers: async function(data) {
    try {
      const response = await instance.get("/director/dealers", {
        headers: {
          'Authorization': data.token
        }
      });
      return response;
    } catch (error){
      throw error;
    }
  },

  getDealers: async function(data) {
    try {
      const encodedGroupName = encodeURIComponent(data.groupName);
      const url = data.groupName ? `/dealerplatform/dealers/${encodedGroupName}` : '/dealerplatform/dealers';

      const response = await instance.get(url, {
        headers: {
          'Authorization': data.token
        }
      });
      return response;
    } catch (error){
      throw error;
    }
  },

  getDealersGroups: async function(data) {
    try {
      const response = await instance.get("/dealerplatform/dealers-groups", {
        headers: {
          'Authorization': data.token
        }
      });
      return response;
    } catch (error){
      throw error;
    }
  }
}
