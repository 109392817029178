import React from 'react';
import {useHistory} from 'react-router-dom';
import BasicPage from "../components/BasicPage";
import { useTranslation } from "react-i18next";

const NewLinkSent = (props) => {
  const history = useHistory();
  const {t} = useTranslation();

  if (history.location.state === undefined) {
    history.push({
      pathname: '/customerboard'
    })

    return null;
  }

  return (
    <>
      <BasicPage icon="icon-confirm-email" title={ t('New link sent') } content={ t(`An email has been sent to {{email}} to accesss to your account.`, { email: history.location.state.email }) } buttonText="Retour" buttonLink="/customerboard" />
    </>
  );
}

export default NewLinkSent
