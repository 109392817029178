import React, { useState, useEffect } from 'react';
import {useHistory} from "react-router-dom";
import {Modal} from "react-bootstrap";
import UserService from "../../services/UserService";
import { useTranslation } from 'react-i18next';

const UserManage = (props) => {

  let [firstName, setFirstName] = useState(props.selectedUser?.first_name ?? '');
  let [lastName, setLastName] = useState(props.selectedUser?.last_name ?? '');
  let [email, setEmail] = useState(props.selectedUser?.email ?? '');

  let [oldEmail, setoldEmail] = useState(props.selectedUser?.old_email ?? '');
  // let [country, setCountry] = useState(props.selectedUser ? props.selectedUser.country : 'France');
  let [language, setLanguage] = useState(props.selectedUser ? props.selectedUser.language : 'fr-FR');
  let [dealership, setDealership] = useState(props.selectedUser?.dealer_id ?? '');
  let [dealerGroup, setDealerGroup] = useState(props.selectedUser?.dealer_group ?? '');
  let [role, setRole] = useState(props.modalType === 'new' ? '' : 'ROLE_DEALER'); // User to be created role, not currentUser's !
  let [dealershipList, setDealershipList] = useState(props.dealerList);
  let [dealerGroupList, setDealerGroupList] = useState(props.dealerGroupList ? props.dealerGroupList : []);
  let [canEditDealer, setCanEditDealer] = useState(false);
  let [canEditCREGroup, setCanEditCREGroup] = useState(role == 'ROLE_DEALER' || role == 'ROLE_DEALER_DIRECTOR' ? true : false);

  const [emailError, setEmailError] = useState(false);
  const [emailMsgError, setEmailMsgError] = useState('');

  const { onChangeModalType } = props;

  useEffect(() => {

    // console.log('props.selectedUser', props.selectedUser);

    if (props.selectedUser === undefined) {
      setEmailError(true);
      setEmailMsgError('Cet email existe déjà et vous n\'avez pas les droits pour modifier cet utilisateur.');
      return;
    }

    if (props.emailModalExist) {
      setEmailError(true);
      setEmailMsgError('Un utilisateur avec cet email existe déjà, vous pouvez le modifier ci-dessous :');
    }

    let dealerID = props.selectedUser?.dealer_id ?? '';

    setFirstName(props.selectedUser?.first_name ?? '');
    setLastName(props.selectedUser?.last_name ?? '');
    setEmail(props.selectedUser?.email ?? '');
    setLanguage(props.selectedUser?.language ?? 'fr-FR');
    setDealership(props.selectedUser?.dealer_id ?? '');
    setDealershipList(props.dealerList);
    setDealerGroup(props.dealerList.filter(dealerItem => dealerID === dealerItem.id)[0]?.dealerGroup ?? '');

    if(props.modalType === "update" || props.modalType === "revoke" ) {
      // console.log('modalType update or revoke');
      if (props.selectedUser.roles.includes('ROLE_ADMIN')) {
        // console.log('and role admin');
        setRole('ROLE_ADMIN');
        setDealerGroup('');
        setDealership('');
        setCanEditCREGroup(false);
        setCanEditDealer(false);
      } else if (props.selectedUser.roles.includes('ROLE_DEALER_DIRECTOR')) {
        setRole('ROLE_DEALER_DIRECTOR');
        setCanEditCREGroup(true);
        setCanEditDealer(false);
        // console.log(dealerGroup);
      } else {
        setRole('ROLE_DEALER');
        if (props.modalType === "update") {
          setCanEditCREGroup(true);
          setCanEditDealer(true);
        }
      }
    }


  }, [props.modalType]);

  const history = useHistory();

  const handleSubmit = (event) => {
    event.preventDefault();
    let language = 'fr-FR';

    if(props.modalType === "new") {

      UserService.userExist({
        "email": email
      })
      .then((resUser) => {

        let dataUser = JSON.parse(resUser.data.roles);

        // ROLE_ADMIN n'est pas testé à cause du BackOffice de NissanLeCLub
        if (dataUser.length === 1 && dataUser[0] === "ROLE_USER") {
          // console.log('User already exist on Nissan Le Club, creating account Dealer Platform...');
          createNewUser();
        } else {
          // console.log('User exist on Dealer Platform', resUser);
          // KO on reload le composant
          onChangeModalType('update', email);
        }
      })
      .catch((error) => {
        if (error.toString().includes('404')) {
          // L'email n'existe pas on crée le user
          createNewUser();
        } else {
          // il y a une autre erreur
          console.log('Error catched', error);
        }
      });

    } else if (props.modalType === "update") {
      UserService.updateUser({
        "token": localStorage.getItem('token'),
        "id": props.selectedUser.id,
        "first_name": firstName,
        "last_name": lastName,
        "email": email,
        "dealer_id": dealership,
        "dealerGroup": dealerGroup,
        "role": role,
      })
      .then((resUser) => {
        if(resUser.status == 200) {
          history.push({
            pathname: '/account-updated',
            state: { email: email }
          });
        }
      })
      .catch((error) => {
        console.log(error);
      })
    } else if (props.modalType === "resend"){
      UserService.sendConfirmDealerEmail({
        "first_name": firstName,
        "last_name": lastName,
        "email": email,
        "old_email": oldEmail,
        "token": localStorage.getItem('token'),
        "dealer_id": dealership,
        "lang": language
      }).then((resUserResend) => {
        if(resUserResend.status == 200 || resUserResend.status == 201){
          history.push({
            pathname: '/reset-link-sent',
            state: { email: email }
          })
        }
      }).catch((error) => {
        console.log(error)
      })
    } else if (props.modalType === "revoke") {
      /* REVOKE LINK */
      UserService.revoke({
        "token": localStorage.getItem('token'),
        "id": props.selectedUser.id
      })
      .then((resUserRevoke) => {
        if(resUserRevoke.status == 200){
          history.push({
            pathname: '/account-deleted',
            state: { email: email }
          })
        }
      })
      .catch((error) => {
        console.log(error)
      })
    } else {
      console.log('other type');
    }
  }

  const createNewUser = () => {
    UserService.createUser({
      "first_name": firstName,
      "last_name": lastName,
      "email": email,
      "token": localStorage.getItem('token'),
      "dealer_id": dealership,
      "dealer_group": dealerGroup,
      "lang": language,
      "role": role,
      "country": 'Fr'
    })
    .then((resUser) => {
      if(resUser.status == 200){
        UserService.sendConfirmDealerEmail({
          "first_name": firstName,
          "last_name": lastName,
          "email": email,
          "old_email": email,
          "token": localStorage.getItem('token'),
          "dealer_id": dealership,
          "lang": language
        }).then((resEmail) => {
          if(resEmail.status == 200){
            history.push({
              pathname: '/account-created',
              state: { email: email }
            })
          }
        }).catch((error) => {
          console.log(error);
        })
      }
    })
    .catch((error) => {
      console.log(error);
    });
  }

  /*** Filters ***/
  const changeRole = (value) => {
    console.log('changeRole', value);
    setRole(value);
    if (value == 'ROLE_DEALER_DIRECTOR' || value == 'ROLE_DEALER') {
      setCanEditCREGroup(true);
      if (value == 'ROLE_DEALER_DIRECTOR') {
        setCanEditDealer(false);
        setDealership('');
      } else if (dealerGroup != '') {
        //console.log('dealerGroup', dealerGroup);
        setCanEditDealer(true);
      }
    } else {
      setCanEditCREGroup(false);
      setCanEditDealer(false);
      setDealership('');
      setDealerGroup('');
    }
  }


  /*** dealerGroup ***/
  const dealerGroupFilterOnChange = (dealerGroupName) => {

    let filteredDealers = props.dealerList
        .filter(item => item.dealerGroup === dealerGroupName)
        .sort((a, b) =>{
          // Sort by name
          if(a.name < b.name) { return -1; }
          if(a.name > b.name) { return 1; }
          // Sort by dealerCode
          if(a.dealerCode < b.dealerCode) { return -1; }
          if(a.dealerCode > b.dealerCode) { return 1; }

          return 0;
        })

    setDealershipList(filteredDealers);
    setDealerGroup(dealerGroupName);

    if(role == 'ROLE_DEALER_DIRECTOR') {
      setCanEditDealer(false);
      if (dealerGroupName != '') {
        setDealership(filteredDealers[0].id); // Premier concessionnaire de la liste
      }
    } else {
      if (dealerGroupName != '') {
        setDealership(filteredDealers[0].id); // Premier concessionnaire de la liste
        setCanEditDealer(true);
      } else {
        setCanEditDealer(false);
      }
    }
  }

  const dealershipOptions = () => {
    // console.log('dealershipList ()', dealershipList);
    return (
      dealershipList.map((data) => {
        return(
          <option value={data.id} key={data.id}>{data.name} ({data.dealerCode})</option>
        )}
      )
    )
  }

  const dealershipGroupOptions = () => {

    if (localStorage.getItem('role').includes('ROLE_DEALER_DIRECTOR')) {
      const groupName = localStorage.getItem('dealerGroup');
      //setCanEditDealer(true);

      return(
          <option value={groupName} key={groupName}>{groupName} </option>
      )
    }

    return (
        dealerGroupList.map((data) => {
          return(
              <option value={data.dealerGroup} key={data.dealerGroup}>{data.dealerGroup} </option>
          )}
        )
    )
  }

  const closeModale = () => {
    props.handleClose();
    /* Reset all state on modale close */
    setFirstName('');
    setLastName('');
    setEmail('');
    setLanguage('');
    setDealership('');
  }

  const { t } = useTranslation();

  /* Conditional display depending of modal Type */
  const displayTitle = () => {
    if (props.modalType === "new") return t('Add a new salesperson');
    else if (props.modalType === "resend") return t('Re-send activation link');
    else if (props.modalType === "update") return t('Update a salesperson');
    else return t('Revoke access to')
  }

  const displayButton = () => {
    if (props.modalType === "new" || props.modalType === "resend") return t('Send activation link');
    else if (props.modalType === "update") return t('Update');
    else return t('Revoke access');
  }

  /* /Conditional display depending of modal Type */

  return (
    <>
    <Modal size="sm" show={props.showModal} centered onHide={() => closeModale()} animation={false} >
        <Modal.Header className="justify-content-center">
          <h2 className="modal-title flex-grow-1">{displayTitle()}</h2>
          <button type="button" className="close" onClick={() => closeModale()}>
            <span aria-hidden="true">
              <span className="icon-close"></span>
            </span>
          </button>
        </Modal.Header>
        <Modal.Body className="px-4 pt-4 pb-4 " >
          {emailError && <div className="color-error font-weight-bold mb-4">{ emailMsgError }</div>}
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="first-name">
                {t('First Name')}
              </label>
              <input required disabled={props.modalType === 'revoke' || props.modalType === 'resend' ? "disabled" : ""} id="first-name" className="form-control" type="text" name="firstname" value={firstName} onChange={(event) => setFirstName(event.target.value)} />
            </div>
            <div className="form-group">
              <label htmlFor="last-name">
                {t('Name')}
              </label>
              <input required disabled={props.modalType === 'revoke' || props.modalType === 'resend' ? "disabled" : ""} id="last-name" className="form-control" type="text" name="lastname" value={lastName} onChange={(event) => setLastName(event.target.value)} />
            </div>
            <div className="form-group">
              <label htmlFor="email">
                Email
              </label>
              <input required disabled={props.modalType === 'revoke' || props.modalType === 'resend' ? "disabled" : ""} id="email" className={`form-control ${emailError ? "error" : ""}`} type="email" name="email" value={email} onChange={(event) => setEmail(event.target.value)} />
              {/* {emailError && <div><span className="color-error">{t('User already exist')}.</span></div>} */}
            </div>
            {/*
            <div className="form-group">
              <label htmlFor="country">
                {t('Country')}
              </label>
                <select disabled={props.modalType === 'revoke' || props.modalType === 'resend ? "disabled" : ""} className="form-control" value={country} onChange={(e) => filterOnChange(e)}>
                  <option disabled selected value="">{t('Select a country')}</option>
                  {dealershipCountryOptions()}
                </select>
            </div>

            <div className="form-group">
              <label htmlFor="language">
                {t('Language')}
              </label>
                {dealershipLanguageOptions()}
            </div>
            */}
            <div className="form-group">
              <label htmlFor="role">
                {t('Role')}
              </label>
              <select required disabled={props.modalType === 'revoke' || props.modalType === 'resend' ? "disabled" : ""} className="form-control" value={role} onChange={(e) => changeRole(e.target.value) }>
                <option value="">{t('Select a role')}</option>
                <option value="ROLE_DEALER" >Vendeur CRE Groupe</option>
                { localStorage.getItem('role').includes('ROLE_ADMIN') &&
                  <option value="ROLE_DEALER_DIRECTOR" >Admin Groupe</option>
                }
                { localStorage.getItem('role').includes('ROLE_ADMIN') &&
                  <option value="ROLE_ADMIN">Super Admin</option>
                }
              </select>
            </div>

            <div className="form-group">
            <label htmlFor="dealerGroup">
              Groupe CRE
            </label>
            <select required disabled={props.modalType === 'revoke' || props.modalType === 'resend' ? "disabled" : !canEditCREGroup} className="form-control" value={dealerGroup} onChange={(e) => dealerGroupFilterOnChange(e.target.value)}>
              <option value="">{t('Select a CRE group')}</option>
              {
                dealershipGroupOptions()
              }
            </select>
          </div>
            <div className="form-group">
              <label htmlFor="dealership">
                {t('Dealership')}
              </label>
              <select required disabled={!canEditDealer} className="form-control" value={dealership} onChange={(e) => setDealership(e.target.value)}>
              <option value="">{t('Select a dealership')}</option>
                {
                  dealershipOptions()
                }
              </select>
            </div>

            <div className="text-center">
              <input type="submit" value={displayButton()} className="button_modal red without-icon" />
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
}


export default UserManage
