import axios from 'axios'

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
      Accept:         'application/ld+json',
      'Content-Type': 'application/json',
  }
});

export default {
  getEventTypes: async function(data) {
    try {
      const response = await instance.get('/dealerplatform/events/types', {
        headers: {
          'Authorization': data.token
        }
      });
      return response;
    } catch (error){
      throw error;
    }
  },

  getUsersForEvent: async function(data) {
    try {
      const encodedEventType = encodeURIComponent(data.eventType);
      const encodedEmail = encodeURIComponent(data.email);
      const response = await instance.get(`/dealerplatform/events/users/${encodedEventType}/${encodedEmail}`, {
        headers: {
          'Authorization': data.token
        }
      });
      return response;
    } catch (error){
      throw error;
    }
  },

  getTimelineCSVForVin: async function(data) {
    try {
      const response = await instance.get(`/dealerplatform/events/export/${data.vin}`, {
        responseType: 'blob',
        headers: {
          'Authorization': data.token
        }
      });
      return response;
    } catch (error){
      throw error;
    }
  },

  getTimelinesCSVForVins: async function(data) {
    try {
      const response = await instance.post(`/dealerplatform/events/export`, {
        vinList: data.vinList,
        responseType: 'blob',
        headers: {
          'Authorization': data.token
        }
      });
      return response;
    } catch (error){
      throw error;
    }
  },
}
