import React, { useState } from 'react';
import {useHistory} from "react-router-dom";
import OnboardLayout from "./OnboardLayout";
import "react-datepicker/dist/react-datepicker.css";

import {useTranslation} from 'react-i18next';
import AddCustomerHeaderButtons from "./AddCustomerHeaderButtons";
import ReactHtmlParser from "react-html-parser";
import UserService from "../../services/UserService";


const UserAddCar = (props) => {
    const history = useHistory();
    const car = JSON.parse(localStorage.getItem('car'));
    const vin = car ? car.vin : '';
    const carId = car ? car.id : '';
    //const customer = JSON.parse(localStorage.getItem('customer'));
    const email = localStorage.getItem('email');
    const [errorAssociate, seterrorAssociate] = useState(false);
    const [confirmAssociate, setconfirmAssociate] = useState(false);

    const handleSubmit = (event) => {

      event.preventDefault();

      UserService.associateUserToCar({
        "carId": carId,
        "email": email
      }).then((res) => {
        if (res.status === 200) {
          UserService.sendCustomerConnectEmail({
            "email": email,
          }).then((res) => {
            setconfirmAssociate(true);
            seterrorAssociate(false);
          }).catch((error) => {
            console.log('error', error);
            seterrorAssociate(error.toString());
          });
        }
      })
      .catch((error) => {
          console.log(error);
          seterrorAssociate(error.toString());
      });
    }

    const {t} = useTranslation();

    if (confirmAssociate) {

      return (

        <div className="wrapper wrapper-with-nav pt-5">
          <div className="container" style={{ textAlign: "center" }}>
            <main>
              <OnboardLayout>
                <div className="row justify-content-center">
                  <div className="col-lg-12 col-xl-10">
                    <div className="white-container">
                      <h2 className="text-center mb-4 mt-5"><span className="icon-check green icon fs-40"></span></h2>
                      <p className="font-weight-bold mb-5">Merci, le véhicule a bien été associé.</p>
                      <p className="font-weight-bold mb-5">{ t(`An email has been sent to {{email}} to accesss to your account.`, { email: history.location.state.email }) }</p>
                      <a href="/customerboard" className="button back red mb-4 m-md-4 d-inline-block">{t('generic.nav_buttons.back_button')}</a>
                    </div>
                  </div>
                </div>
              </OnboardLayout>
            </main>
          </div>
        </div>

      );

    } else {

      return (

        <div className="wrapper wrapper-with-nav pt-5">
          <AddCustomerHeaderButtons />
          <div className="container" style={{ textAlign: "center" }}>
            <main>
              <OnboardLayout>
                <div className="row justify-content-center">
                  <div className="col-lg-12 col-xl-10">
                    <div className="white-container">
                      <h2 className="text-center mb-4 mt-5"><span className="icon-inbox icon fs-40"></span></h2>
                      <p className="font-weight-bold mb-5">{t('The email you wish to add already exist in Nissan Le Club e-Carnet.')}</p>
                      {!errorAssociate && <p className="font-weight-bold mb-4">{ReactHtmlParser(t(`Do you want associate this car {{vin}} with this email {{email}} ?`,{ email: email, vin: vin }))}</p>}

                      {errorAssociate && <p className="font-weight-bold mb-4"><span className="error color-error">Il y a eu une erreur pour associer le compte</span></p>}
                      <form onSubmit={handleSubmit}>
                        <div className="row mb-4">
                          <div className="col-md-12 d-md-flex justify-content-center text-center">
                            <button type="button" onClick={ () => { history.goBack(); } } className="button back white mb-4 m-md-4">{t('generic.forms.form_buttons.cancel')}</button>
                            <button type="submit" className="button red m-md-4">{t('generic.forms.form_buttons.confirm')}</button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </OnboardLayout>
            </main>
          </div>
        </div>

      );
    }

}

export default UserAddCar
