import React, {useState} from 'react';
import {useHistory, useParams} from "react-router-dom";
import OnboardLayout from "./OnboardLayout";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import logoNissanNow from "../../assets/images/logo.png";

import GenericVehicle from "../../assets/images/vehicle/generic.png"
import {useTranslation} from 'react-i18next';
import AddCustomerHeaderButtons from "./AddCustomerHeaderButtons";

import ReactImageFallback from "react-image-fallback";

const UserCarInformation = (props) => {
    let params = useParams();
    const history = useHistory();
    const [email, setEmail] = useState('');
    const [car, setCar] = useState(JSON.parse(localStorage.getItem('car')));

    const handleSubmit = (event) => {
        event.preventDefault();
        history.push({
            pathname: '/user-car-found',
        })
    }

    const {t} = useTranslation();

    return (

        <div className="wrapper wrapper-with-nav pt-5">
            <AddCustomerHeaderButtons />
            <div className="container">
                <main>
                    <OnboardLayout>
                        <div className="text-center board-info-title mb-4">
                            <h1 className="text-center mb-5">{t('CAR FOUND')} <span className="icon-check icon"></span>
                            </h1>
                        </div>
                        <div className="row justify-content-center">
                            <div className="col-lg-12 col-xl-10">
                                <div className="white-container">
                                    <h2 className="with-border">{t('CAR INFORMATION')}</h2>
                                    <form onSubmit={handleSubmit}>
                                        <div className="row">
                                            <div className="col-md-3 text-center d-flex align-items-center">
                                              <div>
                                              <ReactImageFallback
                                                src={localStorage.image}
                                                fallbackImage={GenericVehicle}
                                                alt={"Visuel "+ localStorage.model}
                                                className="mr-auto mb-2"
                                                width="160" />
                                                <p className={'mb-0'}>{localStorage.model}</p>
                                                {car.licencePlate && <p>{car.licencePlate}</p>}
                                              </div>
                                            </div>
                                            <div className="col-md-2">
                                                <p className="lighter-grey-text">{t('VIN')}</p>
                                                <p className="lighter-grey-text">{t('Engine')}</p>
                                                <p className="lighter-grey-text">{t('Grade')}</p>
                                                <p className="lighter-grey-text">{t('Fuel Type')}</p>
                                                <p className="lighter-grey-text">{t('1st registration')}</p>
                                            </div>
                                            <div className="col-md-3">
                                                <p>{car.vin}</p>
                                                <p>{car.engine ?? '---'}</p>
                                                <p>{car.grade ?? '---'}</p>
                                                <p>{car.fuelTypeId ? t(`generic.fuel_type.${car.fuelTypeId}`) : '---'}</p>
                                                <p>{car.firstRegistrationDate ? moment(car.firstRegistrationDate).format('DD/MM/YYYY') : 'Inconnu'}</p>
                                            </div>
                                            <div className="col-md-2">
                                                <p className="lighter-grey-text">{t('Last Maintenance')}:</p>
                                                <p className="lighter-grey-text mt-4">{t('Mileage')}</p>
                                                <p className="lighter-grey-text">Model ID</p>
                                                <p className="lighter-grey-text">Model Name</p>
                                            </div>
                                            <div className="col-md-2">
                                                <p>{car.lastMaintenanceDate && car.lastMaintenanceDate !== '1970-01-01T00:00:00+01:00' ? moment(car.lastMaintenanceDate).format('DD/MM/YYYY') : 'Inconnu'}<br />&nbsp;</p>
                                                <p className="mt-4">{car.currentMileage} KM</p>
                                                <p className="">{car.modelId}</p>
                                                <p className="">{car.modelName}</p>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <button type="submit" className="button red mx-auto mr-md-0">{t('Next')}</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </OnboardLayout>
                </main>
            </div>
        </div>

    );
}

export default UserCarInformation
