import axios from 'axios';
import moment from 'moment';

const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL, headers: {
        Accept: 'application/ld+json', 'Content-Type': 'application/json',
    }
});

export default {
    // Login
    login: async function (data) {

        const response = await instance.post('/authenticate', {
            username: data.username, password: data.password
        });
        return response;
    },

    // Revoke
    revoke: async function (data) {
        try {
            const response = instance.post('/revoke-dealer/', {
                "id": data.id
            }, {
                headers: {
                    'Authorization': localStorage.getItem('token'),
                }
            });
            return response;
        } catch (error) {
            throw error;
        }
    },

    updatePassword: async function (data) {
        try {
            const response = instance.post('/update-password', {
                "uuid": data.uuid, "password": data.password, "validationDate": moment().format()
            }, {
                headers: {}
            });
            return response;
        } catch (error) {
            throw error;
        }
    },

    updateOptin: async function (data) {
        try {
            const response = instance.post('/update-optin', {
                "user_id": data.user_id,
                "emailPersonalized": data.emailPersonalized,
                "smsPersonalized": data.smsPersonalized,
                "emailReminders": data.emailReminders,
                "smsReminders": data.smsReminders,
                "phoneNumber": data.phoneNumber,
            }, {
                headers: {}
            });
            return response;
        } catch (error) {
            throw error;
        }
    },

    // Create User from admin
    createUser: async function (data) {
        try {
            const response = instance.post('/add-user/', {
                "firstName": data.first_name,
                "lastName": data.last_name,
                "gender": 0,
                "birthDate": null,
                "phone": "",
                "email": data.email,
                "password": "Mindoza2021!",
                "optin": false,
                "language": data.lang,
                "status": 1,
                "subscriptionDate": moment().format(),
                "validationDate": "",
                "lastUpdateDate": "",
                "deletionDate": "",
                "newPasswordRequestAt": "",
                "newPasswordRequestToken": "",
                "particleName": "",
                "country": data.country,
                "reminderChannelNotification": false,
                "reminderChannelSMS": false,
                "reminderChannelEmail": false,
                "offerChannelNotification": false,
                "offerChannelSMS": false,
                "offerChannelEmail": false,
                "welcomeTimeline": false,
                "welcomeRewards": false,
                "dealer": data.dealer_id,
                "dealerGroup": data.dealer_group,
                "source": "",
                "medium": "",
                "campaign": "",
                "streetName": "",
                "houseNumber": "",
                "houseNumberExt": "",
                "postalCode": "",
                "city": "",
                "roles": [data.role],
                "boardingFinishDate": "",
                "scheduledDeletionDate": "",
                "countOfUserReward": 0,
                "countOfEmailToRead": 0,
                "exportedToLsm": false
            }, {
                headers: {
                    'Authorization': localStorage.getItem('token'),
                }
            });

            return response;
        } catch (error) {
            throw error;
        }
    },

    // Update User from admin
    updateUser: async function (data) {
        try {
            //const response = instance.post('/update-user/'
            const encodedId = encodeURIComponent(data.id);
            const response = instance.patch(`/dealerplatform/user/${encodedId}`, {
                "id": data.id,
                "firstName": data.first_name,
                "lastName": data.last_name,
                "email": data.email, //"language": data.lang,
                //"country": data.country,
                "dealer": data.dealer_id,
                "dealerGroup": data.dealerGroup,
                "roles": [data.role]
            }, {
                headers: {
                    'Authorization': localStorage.getItem('token'),
                }
            });

            return response;
        } catch (error) {
            throw error;
        }
    },

    sendConfirmDealerEmail: async function (data) {
        try {
            const response = await instance.post("/send-confirm-dealer", {
                "email": data.email,
                "lang": data.lang,
                "old_email": data.old_email,
                "first_name": data.first_name,
                "last_name": data.last_name,
                "dealer_id": data.dealer_id,
            }, {

                headers: {
                    'Authorization': localStorage.getItem('token'),
                }
            });
            return response;
        } catch (error) {
            throw error;
        }
    },

    sendConfirmCustomerEmail: async function (data) {
        try {
            const response = await instance.post("/send-confirm-customer", {
                "email": data.email, "lang": data.lang
            }, {

                headers: {
                    'Authorization': localStorage.getItem('token'),
                }
            });
            return response;
        } catch (error) {
            throw error;
        }
    },

    sendCustomerConnectEmail: async function (data) {
        try {
            const encodedEmail = encodeURIComponent(data.email);
            const response = await instance.get(`/dealerplatform/email/connect/${encodedEmail}`, {
                "email": data.email
            }, {
                headers: {
                    'Authorization': localStorage.getItem('token'),
                }
            });
            return response;
        } catch (error) {
            throw error;
        }
    },

    sendCustomerActivateEmail: async function (data) {
        try {
            const encodedEmail = encodeURIComponent(data.email);
            const response = await instance.post(`/dealerplatform/email/activate/${encodedEmail}`, {
                "email": data.email
            }, {
                headers: {
                    'Authorization': localStorage.getItem('token'),
                }
            });
            return response;
        } catch (error) {
            throw error;
        }
    },

    sendForgortPasswordEmail: async function (data) {
        try {
            const response = instance.post("/email-forgot-password-dealer", {
                "email": data.email
            },);
            return response;
        } catch (error) {
            throw error;
        }
    },

    resetPassword: async function (data) {
        try {
            const response = instance.post('/reset-password', {
                "uuid": data.uuid, "password": data.password
            },);
            return response;
        } catch (error) {
            throw error;
        }
    }, /*
  userRoles: async function(data) {
    try {
        //const response = instance.post("/user-roles",
        const response = instance.get("/user/roles/" + data.email,
          { headers: { 'Authorization': data.token } }
        );
        return response;
    } catch (error){
        throw error;
    }
  },
*/
    userRoles: async function (data) {
        try {
            const encodedEmail = encodeURIComponent(data.email);
            const response = await instance.get(`/user/roles/${encodedEmail}`, {
                headers: {
                    'Authorization': localStorage.getItem('token'),
                }
            });
            return response;
        } catch (error) {
            throw error;
        }
    },

    getDealer: async function (data) {
        try {
            const encodedEmail = encodeURIComponent(data.email);
            const response = await instance.get(`/user/dealer/${encodedEmail}`, {
                headers: {
                    'Authorization': localStorage.getItem('token'),
                }
            });
            return response;
        } catch (error) {
            throw error;
        }
    },

    getDealerGroup: async function (data) {
        try {
            const encodedEmail = encodeURIComponent(data.email);
            const response = await instance.get(`/user/dealergroup/${encodedEmail}`, {
                headers: {
                    'Authorization': localStorage.getItem('token'),
                }
            });
            return response;
        } catch (error) {
            throw error;
        }
    },

    getCar: async function (data) {
        try {
            const response = await instance.get("/customer-infos-by-vin/" + data.vin);
            return response;
        } catch (error) {
            throw error;
        }
    },

    getOnboarders: async function (data) {
        try {
            const response = await instance.get("/onboarders", {
                headers: {
                    'Authorization': localStorage.getItem('token'),
                }
            });
            return response;
        } catch (error) {
            throw error;
        }
    },

    getDealerships: async function (data) {
        try {
            const response = await instance.get("/dealerships", {
                headers: {
                    'Authorization': localStorage.getItem('token'),
                }
            });
            return response;
        } catch (error) {
            throw error;
        }
    },

    getUserById: async function (data) {
        try {
            const response = await instance.get("/user/" + data.id, {
                headers: {
                    'Authorization': localStorage.getItem('token'),
                }
            });
            return response;
        } catch (error) {
            throw error;
        }
    },


    getCarById: async function (data) {
        try {
            const response = await instance.get("/car/id/" + data.id, {
                headers: {
                    'Authorization': localStorage.getItem('token'),
                }
            });
            return response;
        } catch (error) {
            throw error;
        }
    },

    getTimeline: async function (data) {
        try {
            const response = await instance.get("/timeline/car/" + data.carId, {
                headers: {
                    'Authorization': localStorage.getItem('token'),
                }
            });
            return response;
        } catch (error) {
            throw error;
        }
    },

    getDealerTimelines: async function (data) {
        try {
            const response = await instance.get("/dealer-timelines", {
                headers: {
                    'Authorization': localStorage.getItem('token'),
                }
            });
            return response;
        } catch (error) {
            throw error;
        }
    },

    getDirectorTimelines: async function (data) {
        try {
            const response = await instance.get("/director-timelines", {
                headers: {
                    'Authorization': localStorage.getItem('token'),
                }
            });
            return response;
        } catch (error) {
            throw error;
        }
    },

    welcomeTimeline: async function (data) {
        try {
            const response = instance.post("/welcome", {
                "type": data.type
            }, {
                headers: {
                    'Authorization': localStorage.getItem('token'),
                }
            });
            return response;
        } catch (error) {
            throw error;
        }
    },

    reminderSeend: async function (data) {
        try {
            const response = await instance.get("/timeline/event/seen/" + data.eventId, {
                headers: {
                    'Authorization': localStorage.getItem('token'),
                }
            });
            return response;
        } catch (error) {
            throw error;
        }
    },

    getMeUserId: async function (data) {
        try {
            const response = await instance.get("/meuser/" + data.id, {
                headers: {
                    'Authorization': localStorage.getItem('token'),
                }
            });
            return response;
        } catch (error) {
            throw error;
        }
    },

    getMeInfo: async function (data) {
        try {
            const encodedEmail = encodeURIComponent(data.email);
            const response = await instance.get(`/user/me/${encodedEmail}`, {
                headers: {
                    'Authorization': data.token
                }
            });
            return response;
        } catch (error) {
            throw error;
        }
    },

    userEvents: async function (data) {
        try {
            const response = await instance.get(data.url, {
                headers: {
                    'Authorization': localStorage.getItem('token'),
                }
            });
            return response;
        } catch (error) {
            throw error;
        }
    },

    userDealer: async function (data) {
        try {
            const response = await instance.get(data.url, {
                headers: {
                    'Authorization': localStorage.getItem('token'),
                }
            });
            return response;
        } catch (error) {
            throw error;
        }
    },

    userCarTimeline: async function (data) {
        try {
            const response = await instance.get(data.url, {
                headers: {
                    'Authorization': localStorage.getItem('token'),
                }
            });
            return response;
        } catch (error) {
            throw error;
        }
    },

    createCustomer: async function (data) {

        let config = {
            headers: {
                'Authorization': localStorage.getItem('token'),
            }
        };
        try {
            const response = await instance.post("/dealer/add-user", {
                "email": data.email,
                "firstname": data.firstname,
                "middlename": data.middlename,
                "lastname": data.lastname,
                "confidentiality": data.confidentiality,
                "lastMaintenanceDate": data.lastMaintenanceDate,
                "gender": data.gender,
                "startDate": data.startDate,
                "birthdate": data.birthdate,
                "streetName": data.streetName,
                "streetNumber": data.streetNumber,
                "additionalAddress": data.additionalAddress ?? '',
                "postCode": data.postCode,
                "carId": data.carId,
                "mileage": data.mileage,
                "city": data.city,
                "token": localStorage.getItem('token'),
            }, config);
            return response;
        } catch (error) {
            console.log(error)
            throw error;
        }
    },

    associateUserToCar: async function (data) {

        let config = {
            headers: {
                'Authorization': localStorage.getItem('token'),
            }
        };
        try {
            const response = await instance.post("/associate-user-to-car", {
                "carId": data.carId, "email": data.email, "token": localStorage.getItem('token'),
            }, config);
            return response;
        } catch (error) {
            console.log(error)
            throw error;
        }
    },

    userExist: async function (data) {

        let config = {
            headers: {
                'Authorization': localStorage.getItem('token'),
            }
        };

        try {
            const response = await instance.post("/user-exist", {
                "email": data.email,
            }, config);
            return response;
        } catch (error) {
            throw error;
        }
    },
}
