import React, {useEffect, useState} from "react"
import moment from "moment";
import {Link, useHistory, useParams } from "react-router-dom";
import UserService from "../../services/UserService";
import {useTranslation} from 'react-i18next';
import ReactImageFallback from "react-image-fallback";
import GenericVehicle from "../../assets/images/vehicle/generic.png";
import "../../assets/sass/maintenance.sass";
import logoNissanNow from "../../assets/images/logo.png";

import axios from 'axios';
import Loader from "react-loader-spinner";
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import TimelineService from "../../services/TimelineService";


const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    Accept:         'application/ld+json',
    'Content-Type': 'application/json',
  }
});


class BodyClass extends React.Component {
  componentDidMount() {
    document.body.classList.add(this.props.name);
  }
  componentWillUnmount() {
    document.body.classList.remove(this.props.name);
  }
  render() {
    // console.log('render bodyclass');
    return this.props.children || null;
  }
}

const Timeline = () => {

  const {t} = useTranslation();
  const history = useHistory();
  const previousPage = localStorage.getItem('previousPage');
  const [loader, setLoader] = useState(true);

  const [currentUser, setCurrentUser] = useState('');

  // const [car, setCar] = useState('');
  // const [model, setModel] = useState('');
  // const [reminders, setReminders] = useState([]);
  // const [lastUpdateDate, setLastUpdateDate] = useState('2020-02-20T08:56:20+00:00');
  const [allEvents, setAllEvents] = useState([]);
  const [events, setEvents] = useState([]);
  const [currentCars, setCurrentCars] = useState([]);
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('selectedUser')));
  const [havingCurrentContracts, setHavingCurrentContracts] = useState(false);
  const [currentDealer, setCurrentDealer] = useState([]);
  const [selectedCar, setSelectedCar] = useState('');
  const [selectedWhen, setSelectedWhen] = useState(
    {
      value: 'past',
      name: t("timeline.past_events")
    });
  const [times, setTimes] = useState([
    {
      value: 'next',
      name: t("timeline.next_events")
    },
    {
      value: 'past',
      name: t("timeline.past_events")
    }
  ]);

  let user_vin = useParams().vin;
  // let log = 1;
  // const location = useLocation();
  //console.log('vin', user_vin);

  useEffect(() => {
    // console.log('useEffect timeline');

    // LOADING USER DATA
    UserService.getMeUserId({
      "id": user.id, "token": localStorage.getItem('token')
    }).then((res) => {

      if (res.status === 200) {
        if (typeof window !== 'undefined') {
          localStorage.setItem('currentUser', JSON.stringify(res.data.user));
          //console.log('currentUser', res.data.user);
          setCurrentUser(JSON.parse(localStorage.getItem('currentUser')));

          if (0 === res.data.user.cars.length) {
            history.push('/maintenance/timeline-add-car');
          }

          const nbEvents = res.data.user.events.length;
          var events = Array();
          var eventReminders = Array();
          localStorage.setItem('currentEvents', JSON.stringify(events));
          for (let i = 0; i < nbEvents; i++) {
            instance.get(res.data.user.events[i], {
              headers: {
                'Authorization': typeof window !== 'undefined' ? localStorage.getItem('token') : ''
              }
            })
                .then((e) => {
                  if (e.status === 200) {
                    if (typeof window !== 'undefined') {
                      events.push(e.data);

                      if (!e.data.done && !e.data.reminderSeen && moment(new Date()).diff(e.data.reminderDate, 'days') >= 0) {
                        eventReminders.push(e.data);
                      }
                      //console.log('eventReminders', eventReminders);
                      // à quoi ça sert ?
                      //setReminders(eventReminders);
                      localStorage.setItem('currentEvents', JSON.stringify(events));
                    }
                  }
                }).catch((error) => {
              //console.log('error', error)
            });
          }

          // Dealer
          instance.get(res.data.user.dealer, {
            headers: {
              'Authorization': typeof window !== 'undefined' ? localStorage.getItem('token') : ''
            }
          })
              .then((r) => {
                if (r.status === 200) {
                  if (typeof window !== 'undefined') {
                    localStorage.setItem('currentDealer', JSON.stringify({
                      id: r.data.id,
                      name: r.data.name,
                      address: r.data.address,
                      postcode: r.data.postcode,
                      city: r.data.city,
                      phone: r.data.phone,
                      bookingUrl: r.data.bookingUrl
                    }));

                    //console.log('dealer', r.data);
                    setCurrentDealer(r.data);

                    const nbCars = res.data.user.cars.length;
                    var cars = Array();

                    //localStorage.setItem('currentCars', JSON.stringify(cars));


                    for (var i = 0; i < nbCars; i++) {

                      instance.get(res.data.user.cars[i], {
                        headers: {
                          'Authorization': typeof window !== 'undefined' ? localStorage.getItem('token') : ''
                        }
                      })
                          .then((c) => {
                            if (c.status === 200) {
                              if (typeof window !== 'undefined') {
                                //console.log('response car', c)
                                var couple = [];

                                instance.get(c.data.carModel, {
                                  headers: {
                                    'Authorization': typeof window !== 'undefined' ? localStorage.getItem('token') : ''
                                  }
                                })
                                    .then((m) => {
                                      if (m.status === 200) {
                                        if (typeof window !== 'undefined') {
                                          couple.push(c.data);
                                          couple.push(m.data);

                                          cars.push(couple);

                                          cars.sort((a, b) => {
                                            const nameA = a[1].name.toLowerCase();
                                            const nameB = b[1].name.toLowerCase();
                                            return nameA.localeCompare(nameB);
                                          });

                                          if (cars.length === nbCars) {

                                            localStorage.setItem('currentCars', JSON.stringify(cars));
                                            setCurrentCars(cars.map((car, index) => {

                                              if (cars.length - 1 === index && cars[nbCars - 1][0].id === car[0].id) {
                                                getMyTimeline(car[0].id, selectedWhen.value);
                                                setLoader(false);
                                              }

                                              return {
                                                id: car[0].id,
                                                name: car[1].name,
                                                image: car[1].image,
                                                isLeasePlan: car[0].isLeasePlan,
                                                licencePlate: car[0].licencePlate,
                                                firstRegistrationDate: car[0].firstRegistrationDate,
                                                lastMaintenanceDate: car[0].lastMaintenanceDate,
                                                updatedDate: car[0].updatedDate,
                                                lastAutoUpdateDate: car[0].lastAutoUpdateDate
                                              };

                                            }));
                                          }
                                        }
                                      }
                                    }).catch((error) => {
                                  //console.log('error', error)
                                });


                              }
                            }

                          }).catch((error) => {
                        //console.log('error', error)
                      });
                    }

                  }
                }

              }).catch((error) => {
            //console.log('error', error)
          });
        }
      } else if (res.status === 401) {
        history.push('/sessions/logout');
      }
    }).catch((error) => {
      //console.log('error', error);
      history.push('/sessions/logout');
    })
  }, []);

  const getMyTimeline = (carId, when) => {
    //console.log('getMyTimeline', carId, when);
    if (carId !== undefined && selectedWhen.value === when) {
      try{
        let storageSelectedCar = JSON.parse(localStorage.getItem('car')).car;
        if(storageSelectedCar){
          carId = storageSelectedCar.id;
        }
        // console.log('storageSelectedCar', storageSelectedCar);
      } catch (e) {
        // Gestion de la voiture selectionnée
        if (user_vin !== undefined && selectedCar === '') {
          const cars = JSON.parse(localStorage.getItem('currentCars'));
          let selectedCar = cars.filter((car) => {
            return car[0].vin === user_vin;
          })
          let currentCar = {
            id: selectedCar[0][0].id,
            name: selectedCar[0][1].name,
            image: selectedCar[0][1].image,
            isLeasePlan: selectedCar[0][0].isLeasePlan,
            licencePlate: selectedCar[0][0].licencePlate,
            firstRegistrationDate: selectedCar[0][0].firstRegistrationDate,
            lastMaintenanceDate: selectedCar[0][0].lastMaintenanceDate,
            updatedDate: selectedCar[0][0].updatedDate,
            lastAutoUpdateDate: selectedCar[0][0].lastAutoUpdateDate
          };
          localStorage.setItem('car', currentCar);
          //console.log('currentCar getMyTimeline', currentCar);
          setSelectedCar(currentCar);
        }
      }

      instance.get('/timeline/car/' + carId + '/true',
        {
          headers: {
            'Authorization': typeof window !== 'undefined' ? localStorage.getItem('token') : ''
          }
        })
        .then((response) => {
          setAllEvents(response.data.events);
          setEvents(response.data.events['past']);
          if(response.data.carId === carId){
            if (response.data.hasNext === true ) {
              setHavingCurrentContracts(true);
            }else{
              setHavingCurrentContracts(false);
            }
          }

          // à quoi ça sert ?
          // if (response.data.events['past'].length === 0) {
          //   setLastUpdateDate(selectedCar.firstRegistrationDate);
          // } else {
          //   setLastUpdateDate(selectedCar.lastMaintenanceDate)
          // }
        })
        .catch((error) => {
          //console.log('error', error);
        })
    } else {
      setEvents(allEvents[when]);
    }
  };

  const CustomDateFormatter = ({ date }) => {
    const formattedDate = format(new Date(date), 'dd MMMM yyyy', { locale: fr });

    return <span>{formattedDate}</span>;
  };


  const getIconCss = (eventType) => {

    switch(eventType) {
      case 1:
        return 'icon-calendar-maintenance';
      case "Révision":
        return 'icon-controle-technique';
      case "Pneus":
        return 'icon-tire';
      case 5:
        return 'icon-extension-garanti';
      case 6:
        return 'icon-contracts';
      case 7:
        return 'icon-assistance';
      case 8:
        return 'icon-card';
      default:
        return 'icon-atelier';
    }
  }

  const getEvents = () => {
    if (events && events.length > 0) {
      // console.log('getEvents() selectedCar', selectedCar.name, selectedCar.lastMaintenanceDate);
      // console.log(events);
      return (
        <div className="timeline-container">
          {/*Ceci est la timeline de : { selectedCar.name } { selectedCar.licencePlate }*/}
          {events.map((eves, index) => (
            <div className="event" key={eves.date}>
              <div className="event-icon"><span className={getIconCss(eves.data[0].type)} data-type={eves.data[0].type}></span></div>
              <div className={"container-event" + (eves.data[0].isWarranty === true ? ' container-event-warranty' : '') }>
                <header>
                  <h3 className="mb-2"><CustomDateFormatter date={eves.date} /></h3>
                  {eves.data.map((eve, index) => {
                    // console.log(eve)
                    return (
                      <div key={index}>
                        {/*<h3 className="mb-1">{t("timeline.event."+eve.type+".title")}</h3>*/}
                        <div>
                          <h4 className="mb-2">
                            {eve.done &&
                              (
                                <span className="icon-check alert-icon green mr-2"></span>
                              )
                            }
                            {eves.is_contract === true ? "Fin de contrat " + eve.type : eve.type}
                            {eve.isWarranty === true ? " (sous garantie)" : ""}

                            {typeof eve.done !== 'undefined' && !eve.done && (moment(new Date()).diff(eve.dueDate, 'days') >= 0) &&
                              (
                                <span className="icon-time alert-icon"></span>
                              )
                            }
                          </h4>
                        </div>
                        {!eve.done && (moment(new Date()).diff(eve.dueDate, 'months') >= 1 && moment(new Date()).diff(eve.dueDate, 'months') < 2) &&
                          (
                            <div className="alert-message"><span className="icon">!</span> 1 {t("timeline.mentions.month_late")}</div>
                          )
                        }
                        {!eve.done && (moment(new Date()).diff(eve.dueDate, 'months') >= 2 && moment(new Date()).diff(eve.dueDate, 'months') <= 3) &&
                          (
                            <div className="alert-message"><span className="icon">!</span> 2 {t("timeline.mentions.months_late")}</div>
                          )
                        }

                      </div>
                    );
                  })}
                </header>

                {/*{eves.data.map(eve => (*/}
                {/*  <div className="text">*/}
                {/*    /!*{t("timeline.event."+eve.type+".description")}*!/*/}
                {/*  </div>*/}
                {/*))}*/}
              </div>
            </div>
          ))}
        </div>
      );
    } else if ( new Date() - new Date(selectedCar.lastMaintenanceDate) > 24 * 60 * 60 * 1000 ){
      return (
        <div className="alert alert-success">
          <span>Pas d'information connue pour ce véhicule.</span>
        </div>
      );
    } else {
      // console.log('selectedCar', selectedCar.lastMaintenanceUpdatedDate, new Date(selectedCar.lastMaintenanceUpdatedDate));
      return (
        <div className="alert alert-success">
          <span>Merci pour l’ajout de votre véhicule ! Nous recherchons actuellement l’historique que nous avons sur l’entretien de votre véhicule. Dans 24H, s’il y a des informations connues, elles seront visibles ici. A très vite !</span>
        </div>
      );
    }
  };

  const getCars = () => {
    if (currentCars.length > 0 && selectedCar !== undefined) {
      let storageSelectedCar =null;
      let storageSelectedCarModel =null;
      try{
        storageSelectedCar = JSON.parse(localStorage.getItem('car')).car;
        storageSelectedCarModel = JSON.parse(localStorage.getItem('car')).model;
      } catch (e) {

      }

      return (

        <div className="dropdown">
          <a className="dropdown-toggle text darker-grey" href="#" role="button" id="dropdownMenuLink"
             data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            {/*<img src={selectedCar.image} alt={selectedCar.name} className="img-fluid align-middle" width="50"/>*/}
            <ReactImageFallback
              src={storageSelectedCar ? storageSelectedCarModel.image : selectedCar.image}
              fallbackImage={GenericVehicle}
              alt={storageSelectedCar ? storageSelectedCar.name : selectedCar.name}
              className="img-fluid align-middle"
              width="50" />
            <span className="d-none d-md-inline ml-3"><strong>{selectedCar ? selectedCar.name : storageSelectedCarModel.name}</strong> {selectedCar ? selectedCar.licencePlate : storageSelectedCar.licencePlate}</span>
          </a>
          <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
            {currentCars.map(car => (
              <a className="dropdown-item mb-2 mb-lg-1" href="#" key={car.id} onClick={e => selectCar(car, e)}><strong>{car.name}</strong> {car.licencePlate}</a>
            ))}
          </div>
        </div>
      );
    }
  };

  const getTimes = () => {
    if(havingCurrentContracts === true){
      return (
        <div className="dropdown">
          <a className="dropdown-toggle text darker-grey" href="#" role="button" key={selectedWhen.value} id="dropdownMenuLink2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <strong>{selectedWhen.name}</strong>
          </a>

          <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuLink2">
            {times.map(time => (
              <a className="dropdown-item mb-2 mb-lg-1" href="#" key={time.value} onClick={e => selectWhen(time, e)}>{time.name}</a>
            ))}
          </div>
        </div>
      );
    }

  };


  const selectCar = (car, e) => {
    localStorage.setItem('car', car);
    //console.log('car selectCar()', car);
    setSelectedCar(car);
    e.preventDefault();
    setHavingCurrentContracts(false);
    getMyTimeline(car.id, selectedWhen.value);
    //content();
  };

  // Le code est conservé pour les évements futurs
  const selectWhen = (when, e) => {
    setSelectedWhen(when);
    e.preventDefault();

    getMyTimeline(selectedCar.id, when.value);
    //content();
  };


  const getCarsMedia = () =>  {
    let table = []

    if (typeof window !== 'undefined') {
      if (JSON.parse(localStorage.getItem('currentCars'))) {
        for (let i = 0; i < JSON.parse(localStorage.getItem('currentCars')).length; i++) {
          table.push(<li key={i} className="mb-0 py-3 w-100"><a to="/mycars/my-cars" state={{id: JSON.parse(localStorage.getItem('currentCars'))[i][0].id}}>
            <span className="icon">
              <ReactImageFallback
                src={JSON.parse(localStorage.getItem('currentCars'))[i][1].image}
                fallbackImage={GenericVehicle}
                alt={JSON.parse(localStorage.getItem('currentCars'))[i][1].name}
                className="img-fluid"
                width="50" />
            </span>
            <h3 className="mb-0">{JSON.parse(localStorage.getItem('currentCars'))[i][1].name}</h3>
            <p className="mb-0">{JSON.parse(localStorage.getItem('currentCars'))[i][0].licencePlate}</p></a></li>)
        }
      }
    }
    return table
  }

  const getExportToExcel = () => {
    const downloadCSV = async () => {
      const link = document.createElement('a');

      try {
        TimelineService.getTimelineCSVForVin({
          token: localStorage.getItem('token'),
          vin: selectedCar.id
        })
            .then((response) => {
              const url = window.URL.createObjectURL(new Blob([response.data]));
              link.href = url;
              link.setAttribute('download', 'fichier.csv'); // Nom du fichier à télécharger
              document.body.appendChild(link);
              link.click();

              // Nettoyer et enlever le lien
              link.parentNode.removeChild(link);
              window.URL.revokeObjectURL(url);
            })
            .catch((error) => {
              console.log(error);
            });
      } catch (error) {
        console.error('Il y a eu un problème avec la demande de téléchargement: ', error);
      }
    }
    return (
        <a onClick={downloadCSV} className="icon-download cursor-pointer" title="Exporter dans un fichier les informations sur ce client et sur les évènements de son véhicule"></a>
    )
  }

  const logout = () => {
    localStorage.removeItem('token');
    localStorage.clear();
    history.push('/');
  };

  const content = () => {
    // console.log('content() log', log++);
    return(
      <div className="row py-4">
        <div className="col-md-12">
          <div className="py-4">
            <Link to={'/'+previousPage} className="button white back align-self-center fs-13"><span>{t('Back to client list')}</span></Link>
          </div>
        </div>
        <div className="col-lg-4 d-none d-lg-block without-arrow">
          <div className="status-container white-container py-3 px-3 mb-3">
            <h3>{ currentUser.firstName } { currentUser.lastName }</h3>
            { currentUser.email }<br />
            { currentUser.phone}
          </div>
          <h3 className="text-uppercase">{t('my_profil.my_dealer.title')}</h3>
          <div className="white-container py-2 px-3 mb-3">
            <ul className="list-link-picto">
              <li className="mb-0 py-3 w-100">
                <a to="/profile/my-dealer" state={{id: currentDealer.id}} className="my-dealer">
                  <span className="icon-dealer-favorite icon"></span>
                   <h3 className="mb-0">{currentDealer.name}</h3>
                  ({currentDealer.dealerCode})
                </a>
              </li>
            </ul>
          </div>
          <h3 className="text-uppercase">{t('my_profil.my_vehicles')}</h3>
          <div className="white-container py-3 px-3 mb-3">
            <ul className="list-link-picto">
              { getCarsMedia()}
            </ul>
          </div>
        </div>
        <div className="col-md-8">
          <div className="white-container position-relative">
            <div className="dld-export fs-40 float-right">
              {getExportToExcel()}
            </div>

            <h1 className="text-uppercase with-border-top with-border-bottom fs-20">{t("timeline.title")}</h1>
            <p>{t("timeline.description")}</p>

            <div className="d-flex align-items-center justify-content-between">
              {getCars()}
              {getTimes()}
            </div>

            <hr className="mt-2" />

            {
              loader ? (
                <div style={{ textAlign: "center" }}>
                  <Loader
                    type="TailSpin"
                    color="#C0092D"
                    height={100}
                    width={100}
                  />
                </div>
              ) : (
                getEvents()
              )
            }

          </div>
        </div>
      </div>
    )
  };

  return (

    <div className="wrapper">
      <div className="container">
        <main>
          <header className="header py-2">
            <div className="container d-flex align-items-center">
              <img src={logoNissanNow} alt="Nissan Le Club" width="160" className="mr-auto logo-width"/>
              <span className="dark-red cursor-pointer" onClick={() => logout()}><span
                className="icon-logout-2 icon align-middle"></span>{t('Logout')}</span>
            </div>
          </header>
          <br /><br /><br />
          {content()}
        </main>
        <BodyClass name="large-wrapper" />
        <footer className="d-flex justify-content-around d-md-block text-center footer">
          <div className="container">
            {/*<a href={t('url_terms')} target="_blank" className="mr-3">{t('footer.terms')}</a>*/}
            {/*<a href={t('url_privacy')} target="_blank">{t('footer.privacy')}</a>*/}
            <a href={t('url_cookies')} target="_blank" className="ml-3">{t('FAQ / Contact')}</a>
          </div>
        </footer>
      </div>
    </div>
  )
};

export default Timeline

