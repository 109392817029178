import React, { useState } from 'react';
import { useHistory, useParams } from "react-router-dom";
import BasicLayout from "../../components/BasicLayout";
import UserService from "../../services/UserService";
import logoNissanNow from "../../assets/images/logo.png";
import { useTranslation } from 'react-i18next';

const UserChoosePassword = (props) => {
  const history = useHistory();
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  let user_id = useParams();

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!passwordError && password !== ''){
      //Appel API
      UserService.updatePassword({
        "uuid": user_id,
        "password": password
      })
      .then((res) => {
        localStorage.setItem('user_id', JSON.stringify(user_id));
        history.push({
          pathname: '/user-contact-preferences'
        })
      })
      .catch((error) => {
        console.log(error)
      })
    }
  }

  const handleChangePassword = (event) => {
    setPassword(event.target.value);
    setPasswordError(!validatePassword(event.target.value));
  }

  const validatePassword = (string) =>{
    var reg = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/g;
    return reg.test(string);
  }

  const { t } = useTranslation();

  return (
    <div className="wrapper wrapper-with-nav pt-5">
            <header className="header py-2">
                <div className="container d-flex align-items-center">
                    <img src={logoNissanNow} alt="Nissan Le Club" width="160" className="mr-auto logo-width"/>
                </div>
            </header>

            <div className="bar-step">

                <a href="/user-set-email" className="active step1">
                    <span className="icon-warranty icon"></span>
                    <span className="d-none d-md-block text">{t('SET YOUR PASSWORD')}</span>
                </a>
                <a href="/user-client-info" className="step2">
                    <span className="icon-inbox icon"></span>
                    <span className="d-none d-md-block text text-dealer-favorite">{t('YOUR CONTACT PREFERENCES')}</span>
                </a>
            </div>
    <BasicLayout>

      <h2 className="with-border">{t('Choose your password')}</h2>
      <p>{t('Please choose a password to activate')}.</p>

      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-md-6 mb-4">
            <div className="form-group">
              <label className="required form-label">{t('CHOOSE A NEW PASSWORD')}</label>
              <div className="password-wrapper">
                <input type={isPasswordVisible ? "text" : "password"} name="password" value={password} onChange={(event) =>handleChangePassword(event)} className={`form-control ${passwordError ? "error" : ""}`} />
                <span className={"icon-eye toggle-pwd"} onClick={()=> setIsPasswordVisible(!isPasswordVisible)}></span>
              </div>
              {passwordError && <div><span className="error color-error">{t('Minimum of 8 characters, should at least include one digit, one capital letter')}</span></div>}
            </div>
          </div>
        </div>
        <div className="">
            <button type="submit" className="button red mx-auto">{t('Submit this password')}</button>
        </div>
      </form>
    </BasicLayout>
    </div>
  );
}


export default UserChoosePassword
