import React, {useState, useEffect} from 'react';
import {useHistory, useParams} from "react-router-dom";
import OnboardLayout from "./OnboardLayout";
import UserService from "../../services/UserService";
import { fr } from 'date-fns/locale';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import classNames from 'classnames/bind';

import logoNissanNow from "../../assets/images/logo.png";

import {useTranslation} from 'react-i18next';
import AddCustomerHeaderButtons from "./AddCustomerHeaderButtons";


const UserLastMaintenance = (props) => {
    let params = useParams();
    const history = useHistory();
    const [lastMaintenanceDate, setLastMaintenanceDate] = useState(new Date());
    const [model, setModel] = useState(localStorage.model);
    const [updatedDate, setUpdateDate] = useState('');
    const [car, setCar] = useState(JSON.parse(localStorage.getItem('car')));
    const [checkDateClass, setCheckDateClass] = useState(car.lastMaintenanceDate ? 'multiline-button' : '');
    const [datepickerDate, setDatepickerDate] = useState(car.lastMaintenanceDate ?? null);

    const handleSubmit = (event) => {
        event.preventDefault();
        localStorage.setItem('lastMaintenanceDate', moment(lastMaintenanceDate).format('YYYY-MM-DD'))
        history.push({
            pathname: '/user-set-email',
            state: {
                email: localStorage.email,
                car: localStorage.car

            }
        })
    }

    const setDate = (amount, unit) => {
        setLastMaintenanceDate(moment(moment().add(amount, unit).format(), moment.defaultFormat).toDate());
        setCheckDateClass('')
    }
    const onDatePickerChange = (date) => {
        setUpdateDate(date);
        setDatepickerDate(date);
        setLastMaintenanceDate(date)
        // Clear all selected radio button
        let ele = document.getElementsByName("latest-maintenance");
        for(let i=0;i<ele.length;i++) {
          ele[i].checked = false;
        }
        setCheckDateClass('multiline-button')
      }

      const datepickerClass = classNames({
        'button white': true,
        'multiline-button': datepickerDate
      });

    const {t} = useTranslation();

    return (

        <div className="wrapper wrapper-with-nav pt-5">
            <AddCustomerHeaderButtons />
            <div className="container">
                <main>
                    <OnboardLayout>
                        <div className="text-center board-info-title mb-4"></div>
                        <div className="row justify-content-center">
                            <div className="col-lg-12 col-xl-10">
                                <div className="white-container">
                                <h2 className="with-border">{t('When was the last maintenance')}</h2>
                                <form onSubmit={handleSubmit}>
                                    <div className="row">
                                        <div className="col-md-4 text-center">
                                          <img src={localStorage.image} alt={"Visuel "+ localStorage.model} width="160"
                                               className="mr-auto mb-2"/>
                                            <p>{model}</p>
                                            {car.licencePlate && <p>{car.licencePlate}</p>}
                                        </div>
                                        <div className="col-md-4">
                                            <div className="radio-date">
                                                <input name="latest-maintenance" type="radio" value="1970-01-01T00:00:00+01:00"
                                                       onClick={((e) => setDate(-1, 'years'))}
                                                       id="latest-maintenance-1" className="form-control"></input>
                                                <label htmlFor="latest-maintenance-1" className="button white"><span>{t('The car has less than one year, has not had any maintenance yet')}</span></label>
                                            </div>
                                            <div className="radio-date">
                                                <input name="latest-maintenance" type="radio"
                                                       value={lastMaintenanceDate}
                                                       onClick={((e) => setDate(-3, 'months'))}
                                                       id="latest-maintenance-2" className="form-control"></input>
                                                <label htmlFor="latest-maintenance-2"
                                                       className="button white"><span>1 - 3 {t('months')}</span></label>
                                            </div>
                                            <div className="radio-date">
                                                <input name="latest-maintenance" type="radio"
                                                       value={lastMaintenanceDate}
                                                       onClick={((e) => setDate(-1, 'years'))}
                                                       id="latest-maintenance-3" className="form-control"></input>
                                                <label htmlFor="latest-maintenance-3"
                                                       className="button white"><span>6 - 12 {t('months')}</span></label>
                                            </div>

                                            <div className="radio-date select-date">
                                                <label htmlFor="latest-maintenance-4" id="latest-maintenance-4" className={'button white '+checkDateClass}><span>
                                                    {t('generic.forms.form_options.last_maintenance.6')}<br/>
                                                    {datepickerDate && datepickerDate !== '1970-01-01T00:00:00+01:00' ? moment(datepickerDate).format("DD/MM/YYYY") : ""}</span>
                                                </label>
                                                <DatePicker className="form-control"
                                                            dateFormat="dd/MM/yyyy"
                                                            peekNextMonth
                                                            showMonthDropdown
                                                            showYearDropdown
                                                            dropdownMode="select"
                                                            locale={fr}
                                                            maxDate={new Date()}
                                                            selected={updatedDate ? new Date(updatedDate) : new Date()}
                                                            onChange={((date) => {
                                                                onDatePickerChange(date);
                                                            })}/>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="radio-date">
                                                <input name="latest-maintenance" type="radio"
                                                       value={moment().add(-1, 'months').format()}
                                                       onClick={((e) => setDate(-1, 'month'))}
                                                       id="latest-maintenance-5" className="form-control"></input>
                                                <label htmlFor="latest-maintenance-5" className="button white"><span>{t('Less than a month')}</span></label>
                                            </div>
                                            <div className="radio-date">
                                                <input name="latest-maintenance" type="radio"
                                                       value={moment().add(-6, 'months').format()}
                                                       onClick={((e) => setDate(-6, 'month'))}
                                                       id="latest-maintenance-6" className="form-control"></input>
                                                <label htmlFor="latest-maintenance-6"
                                                       className="button white"><span>3 - 6 {t('months')}</span></label>
                                            </div>
                                            <div className="radio-date">
                                                <input name="latest-maintenance" type="radio"
                                                       value={moment().add(-2, 'years').format()}
                                                       onClick={((e) => setDate(-2, 'years'))}
                                                       id="latest-maintenance-7" className="form-control"></input>
                                                <label htmlFor="latest-maintenance-7" className="button white"><span>{t('More than 12 months')}</span></label>
                                            </div>
                                        </div>
                                    </div>
                                    <button type="submit" className="button red mx-auto mr-md-0">{t('Next')}</button>
                                </form>
                                </div>
                            </div>
                        </div>
                    </OnboardLayout>
                </main>
            </div>
        </div>

    );
}

export default UserLastMaintenance
